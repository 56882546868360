const numberFormats = {
  delimiters: {
    thousands: ',',
    decimal: '.',
    precision: 2
  },
  abbreviations: {
    thousand: 'k',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  ordinal : function (number) {
    return number === 1 ? 'er' : 'º'
  },
  currency: {
    symbol: '$'
  }
}

export default numberFormats;
