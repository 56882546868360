const tenantCustonConfig = {
  brandName: "Punto",
  dropdownMenuItems: [
    'buyReader',
  ],
  automaticCashout: {
    isEnabled: false,
  },
  currencies: [{ value: "484", label: "Pesos", code: "MXN", symbol: "$" }],
  disabledCreateNewAdminUser:true,
  termsAndConditions: false,
  showDocumentType: false,
  showPhoneNumber: false,
  showBankAccountSelectForm: true,
  sales: {
    showFilterSubsidiariesWidget: true,
    options: {
      type: [
        { value: "", label: "transactions.optionVoid" },
        { value: "SALE", label: "transactions.types.SALE" },
        { value: "ANNULMENT", label: "transactions.types.ANNULMENT" },
        { value: "CHARGEBACK", label: "Devolución" },
      ],
      status: [
        { value: "", label: "Todos" },
        { value: "APPROVED", label: "Aprobada" },
        { value: "PENDING", label: "Pendiente" },
      ],
      formats: [{ value: "xls", label: "Excel" }],
    },
    transactionReceipt: {
      summary: [
        {
          label: "Titular de tarjeta",
          method: (sale) => sale.payment.cardHolderName,
        },
        {
          label: "Terminal ID",
          method: (sale) => sale.terminalID,
        },
        {
          label: "Fecha de disponibilidad",
          method: (sale) => sale.availableDate,
        },
        {
          label: "N° de referencia",
          method: (sale) => sale.referenceNumber,
        },
        {
          label: "Nº de autorización",
          method: (sale) => sale.authorizationCode,
        },
      ],
    },
  },
  deposits: {
    options: {
      formats: [
        { value: 'xls', label: 'Excel' },
        { value: 'pdf', label: 'PDF' }
      ],
    },
  },
};

const tenantTableColumns = {
  "salesColumns": [
    "description",
    "date",
    "amount",
    "deferredPayments",
  ],
  userFields: [
    'email',
    'firstName',
    'lastName',
    'roleId',
    'subsidiaryId',
    'statusId',
  ]
};

export { tenantCustonConfig, tenantTableColumns };
